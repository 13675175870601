import { LocationContext } from "@reach/router";
import * as React from "react";
import Auth from "src/components/Auth";
import ModalWrapper from "src/components/ModalWrapper";
import Seo from "src/components/Seo";

const LoginPage = (props: LocationContext) => {
  return (
    <ModalWrapper>
      <Seo pageTitle="Страница входа" />
      <Auth {...props} type="LOGIN" />
    </ModalWrapper>
  );
};

export default LoginPage;
